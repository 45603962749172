import React from "react"
import SEO from "../components/seo"

import Hero from "../components/hero"
// import Topics from "../components/topics"
// import Schedule from "../components/schedule"
// import Speakers from "../components/speakers"
// import Join from "../components/join"
// import Footer from "../components/footer"

import "../styles/style.css"

export default function Home() {
  return (
    <>
      <SEO />
      <main>
        <Hero />
        {/* <Topics />
        <Schedule />
        <Speakers />
        <Join />
        <Footer /> */}
      </main>
    </>
  )
}
