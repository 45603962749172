import React from "react"
import { Helmet } from "react-helmet"

const SEO = () => {
  return (
    <Helmet>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* <meta name="viewport" content="min-width=1440"/> */}
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <title>Country Fellows</title>
      {/* <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0c344b" />
      <meta property="og:title" content="Prisma Day 2020" />
      <meta
        property="og:description"
        content="A one day, single-track conference on modern application development and databases. Learn from database and application engineering leaders in an interactive online event."
      />
      <meta
        property="og:image"
        content="https://www.prisma.io/day/images/og-image.png"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="675" />
      <meta property="og:url" content="https://www.prisma.io/day/" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="apple-mobile-web-app-title" content="Prisma" />
      <meta name="application-name" content="Prisma" />
      <meta name="msapplication-TileColor" content="#0c344b" />
      <meta name="theme-color" content="#ffffff" /> */}
      <meta
        name="description"
        content="Country Fellows-coming soon!"
      />
    </Helmet>
  )
}

export default SEO
