import React from "react"
import styled from "styled-components"
import BgImg from '../../static/background.jpeg'

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  color: #ffffff;
  position: relative;
  .text-holder {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 225px;
  }
  span {
    font-size: 100px;
    font-family: "Nothing You Could Do", cursive;
    font-weight: bold;
    // margin-bottom: -95px;
    z-index: 2;
    //margin-top: 225px;
    position: absolute;
    top: -40px;
  }
  h1 {
    font-size: 140px;
    color: #ffd11a;
    font-family: "Anton";
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;
  }
  p {
    font-size: 32px;
    font-family: "Anton";
    z-index: 1;
    margin-top: 0;
  }
  background-image: url(${BgImg});
  background-size: cover;
  background-color: #cccccc;
  background-repeat: no-repeat;
  position: relative;

  @media (min-width: 501px) and (max-width: 950px) {
    h1 {
      font-size: 80px;
    }

    span {
      font-size: 60px;
      top: -24px;
    }
  }


  @media (min-width: 0px) and (max-width: 500px) {
    background-position: -300px;
    span,
    h1 {
      font-size: 45px;
    }

    span {
      top:-22px;
    }

    h1 {
      margin-bottom: 100px;
    }
    p {
      font-size: 24px;
    }
  }
`

const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.4;
`

const Hero = () => {
  return (
    <HeroWrapper>
      <Shadow />
      <div className="text-holder">
      <span>We are</span>
      <h1>Country Fellows</h1>
      </div>
      <p>Coming soon</p>
    </HeroWrapper>
  )
}

export default Hero
